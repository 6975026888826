import { DOCUMENT } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@ct/auth';
import {
  DialogService,
  ImageUploadService,
  InformationDialogComponent,
  SelectOption,
  SpinnerService
} from '@ct/components';
import { DialogConfig } from '@ct/components/dialog/interfaces';
import {
  DestroyableFeature,
  Features,
  FormStateDispatcher,
  LocalStorageService,
  UploadedImage,
  UserProfile
} from '@ct/core';
import { BaseAutosaveFormComponent, entitySlugUrl, Mode, MyAccountPhotoApiService } from '@ct/shared';
import { TranslateService } from '@ngx-translate/core';
import { QuillModules } from 'ngx-quill';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

import { Currency, MarketplaceItemCondition } from '../../enums';
import { MarketplaceItem, SubCategory } from '../../interfaces';
import { MarketplaceApiService, MerchantProfileApiService } from '../../services';
import { MarketplaceQuery } from '../../services/state';

const QUILL_MODULES_CONFIG: QuillModules = {
  toolbar: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', { list: 'bullet' }, 'link', 'image']]
};

const MARKETPLACE_ITEM_AUTOSAVE_CONFIG = {
  autosaveKey: 'MARKETPLACE_ITEM_CREATE_AUTOSAVE',
  excludeFormFields: ['status'],
  dialogConfig: {
    titleKey: 'MARKETPLACE.AUTOSAVE_DIALOG.TITLE',
    messageKey: 'MARKETPLACE.AUTOSAVE_DIALOG.MESSAGE',
    confirmKey: 'MARKETPLACE.AUTOSAVE_DIALOG.CONFIRM',
    cancelKey: 'MARKETPLACE.AUTOSAVE_DIALOG.CANCEL'
  }
};

const PHOTOS_LIMIT = 36;

@Component({
  selector: 'ct-create-marketplace-item',
  templateUrl: './create-marketplace-item.component.html',
  styleUrls: ['./create-marketplace-item.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class CreateMarketplaceItemComponent extends BaseAutosaveFormComponent implements OnInit {
  public readonly destroyed$: Observable<void> = new Subject();
  public readonly categories$ = this.marketplaceQuery.selectCategories$;
  public readonly photosLimit = PHOTOS_LIMIT;

  public modules: QuillModules = QUILL_MODULES_CONFIG;
  public isLoading = false;
  public categoriesList: SelectOption[] = [];
  public subCategoriesMap = new Map<string, SubCategory[]>();
  public subCategoriesList: SelectOption[] = [];
  public readonly currencyList = [
    {
      value: Currency.USD,
      label: Currency.USD
    },
    {
      value: Currency.CAD,
      label: Currency.CAD
    },
    {
      value: Currency.EUR,
      label: Currency.EUR
    }
  ];
  public readonly conditionsList = [
    {
      value: MarketplaceItemCondition.UsedWell,
      label: this.translateService.instant('MARKETPLACE.CONDITION.USED_WELL')
    },
    {
      value: MarketplaceItemCondition.UsedSomeWear,
      label: this.translateService.instant('MARKETPLACE.CONDITION.USED_SOME_WEAR')
    },
    {
      value: MarketplaceItemCondition.LikeNew,
      label: this.translateService.instant('MARKETPLACE.CONDITION.LIKE_NEW')
    },
    {
      value: MarketplaceItemCondition.New,
      label: this.translateService.instant('MARKETPLACE.CONDITION.NEW')
    },
    {
      value: MarketplaceItemCondition.NewInPackage,
      label: this.translateService.instant('MARKETPLACE.CONDITION.NEW_IN_PACKAGE')
    }
  ];

  public showAmazonLinkControl = new UntypedFormControl(false);
  public showAmazonLinkInput = false;

  public readonly form: UntypedFormGroup;
  public currentUser: UserProfile | null;

  get item(): MarketplaceItem {
    return this.route.snapshot.data.item;
  }

  get photos() {
    return this.form.controls.photos?.value;
  }

  get isEditMode() {
    return this.route.snapshot.data?.mode === Mode.Edit;
  }

  constructor(
    protected localStorageService: LocalStorageService,
    protected dialogService: DialogService,
    protected translateService: TranslateService,
    private formState: FormStateDispatcher,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    private spinnerService: SpinnerService,
    private changeDetectorRef: ChangeDetectorRef,
    private imageUploadService: ImageUploadService,
    private marketplaceApiService: MarketplaceApiService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private merchantProfileApiService: MerchantProfileApiService,
    private authService: AuthService,
    private marketplaceQuery: MarketplaceQuery
  ) {
    super(localStorageService, dialogService, translateService, MARKETPLACE_ITEM_AUTOSAVE_CONFIG);

    this.authService
      .getUserProfile()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (userProfile) => {
          this.currentUser = userProfile;
        },
        () => {
          this.currentUser = null;
        }
      );
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(),
      title: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl('', [Validators.required]),
      brand: new UntypedFormControl('', [Validators.required]),
      modelTitle: new UntypedFormControl('', [Validators.required]),
      price: new UntypedFormControl(null, [Validators.required]),
      currency: new UntypedFormControl(this.currencyList[0].value, [Validators.required]),
      condition: new UntypedFormControl(this.conditionsList[0].value, [Validators.required]),
      categoryId: new UntypedFormControl('', [Validators.required]),
      subCategoryId: new UntypedFormControl({ value: '', disabled: true }),
      photos: new UntypedFormControl([]),
      sold: new UntypedFormControl(false),
      amazonAffiliateUrl: new UntypedFormControl(''),
      address: new UntypedFormGroup({
        city: new UntypedFormControl('', [Validators.required]),
        state: new UntypedFormControl('', [Validators.required]),
        zip: new UntypedFormControl('', [Validators.required]),
        locationLabel: new UntypedFormControl('')
      }),
      alternateContact: new UntypedFormGroup({
        firstName: new UntypedFormControl(''),
        lastName: new UntypedFormControl(''),
        email: new UntypedFormControl('', [Validators.email]),
        confirmEmail: new UntypedFormControl('', [Validators.email]),
        phone: new UntypedFormControl('')
      })
    });
    const categoryControl = this.form.get('categoryId') as UntypedFormControl;
    const subCategoryControl = this.form.get('subCategoryId') as UntypedFormControl;

    categoryControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      const subCategories = this.subCategoriesMap.get(value) ?? [];
      this.subCategoriesList = subCategories.map(({ id, title }) => ({
        value: id as string,
        label: title as string
      }));
      subCategoryControl.enable();
      if (!this.subCategoriesList.some(({ value }) => value === subCategoryControl.value)) {
        subCategoryControl.setValue('');
      }
    });

    this.validateConfirmEmail();
  }

  ngOnInit() {
    this.categories$.pipe(takeUntil(this.destroyed$)).subscribe((categories) => {
      this.categoriesList = categories.reduce((acc, { id, title, subCategories }) => {
        this.subCategoriesMap.set(id as string, subCategories as SubCategory[]);
        acc.push({ value: id as string, label: title as string });
        return acc;
      }, [] as SelectOption[]);
    });

    if (this.isEditMode && this.item) {
      this.loadItemAndPhotos();
    } else {
      this.setForm(this.form);
      this.loadMerchantPhone();
      this.getAutosavedItem()
        .pipe(take(1))
        .subscribe((autosavedItem) => {
          if (!autosavedItem) {
            this.stopAutosave();
            this.startAutosave();
            return;
          }
          this.form.patchValue(autosavedItem);
          this.startAutosave();
        });
    }

    this.showAmazonLinkControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((showAmazonLink) => {
      // TODO check user id
      this.showAmazonLinkInput = showAmazonLink;
      this.toggleAmazonValidations(showAmazonLink);
    });
  }

  loadItemAndPhotos() {
    const photosRequest$ = this.item.photoIds?.length
      ? this.myAccountPhotoApiService.getByIds(this.item.photoIds)
      : of([]);
    photosRequest$
      .pipe(map((photos) => ({ ...this.item, photos: photos.map((photo) => ({ ...photo, id: String(photo?.id) })) })))
      .subscribe(({ ...item }) => {
        this.form.patchValue({ ...item });
        if (item.amazonAffiliateUrl?.trim()) {
          this.showAmazonLinkControl.patchValue(true);
          this.showAmazonLinkInput = true;
          this.toggleAmazonValidations(true);
        }
      });
  }

  loadMerchantPhone() {
    this.merchantProfileApiService.getMyProfile().subscribe(({ phone }) => {
      this.form.get('alternateContact.phone')?.patchValue(phone, { emitEvent: false, onlySelf: true });
    });
  }

  onUpdate() {
    this.createOrUpdateItem();
  }

  onPublish() {
    this.createOrUpdateItem();
  }

  onCancel() {
    return this.dialogService
      .open(InformationDialogComponent, {
        data: {
          title: this.translateService.instant('MARKETPLACE.EDIT_ITEM.CANCEL_EDIT.TITLE'),
          message: this.translateService.instant('MARKETPLACE.EDIT_ITEM.CANCEL_EDIT.MESSAGE'),
          cancel: this.translateService.instant('MARKETPLACE.EDIT_ITEM.CANCEL_EDIT.CANCEL'),
          confirm: this.translateService.instant('MARKETPLACE.EDIT_ITEM.CANCEL_EDIT.CONFIRM')
        },
        width: '450px'
      } as MatDialogConfig<DialogConfig>)
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.stopAutosave();
        this.router.navigate(['..'], {
          relativeTo: this.route
        });
      });
  }

  createOrUpdateItem() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      this.scrollToFirstError();
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    const { id, ...postForm } = this.form.value;
    const { confirmEmail, ...alternateContact } = postForm.alternateContact;

    const marketplaceItemPayload = { ...postForm, alternateContact };

    if (postForm.amazonAffiliateUrl?.trim()) {
      marketplaceItemPayload.alternateContact = null;
      marketplaceItemPayload.address = null;
      marketplaceItemPayload.price = null;
      marketplaceItemPayload.currency = null;
    } else {
      marketplaceItemPayload.amazonAffiliateUrl = null;
    }

    return (
      !id
        ? this.marketplaceApiService.create(marketplaceItemPayload)
        : this.marketplaceApiService.update(id, marketplaceItemPayload)
    )
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe(
        (item) => this.navigateToView(item),
        (error) => {
          if (error.status === 400) {
            if (error.error.field === 'address') {
              const addressGroup = this.form.get('address') as UntypedFormGroup;
              Object.values(addressGroup.controls).forEach((control) => {
                control.setErrors({ invalid: true });
              });

              addressGroup.valueChanges.pipe(take(1)).subscribe(() => {
                Object.values(addressGroup.controls).forEach((control) => {
                  control.setErrors(null);
                });
              });
            }
          }
        }
      );
  }

  onToggleSold() {
    const { id, sold } = this.form.value;
    this.marketplaceApiService.markAsSold(id, !sold).subscribe(() => this.navigateToView(this.item));
  }

  onAddPhotos() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.ADD_PHOTOS',
        multiple: true,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTOS',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) =>
          this.myAccountPhotoApiService.uploadWithProgress(file).pipe(
            switchMap((event: any) => {
              if (event?.type !== HttpEventType.Response) {
                return of(event);
              }
              return this.myAccountPhotoApiService.update(event?.body?.data.id, { isMarketplaceItem: true }).pipe(
                map(() => {
                  event.body.data.isMarketplaceItem = true;
                  return event;
                }),
                catchError(() => of(event))
              );
            })
          )
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((photos) => {
        const allPhotos = [...this.form.controls.photos.value, ...photos];
        const uniquePhotos = allPhotos.filter(
          (photo, index) => allPhotos.findIndex(({ id }) => id === photo.id) === index
        );
        this.form.controls.photos.patchValue(uniquePhotos.slice(0, PHOTOS_LIMIT));
        this.changeDetectorRef.detectChanges();
      });
  }

  onRemovePhoto({ id }: UploadedImage) {
    const photos = this.photos.filter((photo: UploadedImage) => photo.id !== id);
    this.form.controls.photos.patchValue(photos);
    this.changeDetectorRef.detectChanges();
  }

  navigateToView(item: MarketplaceItem) {
    this.stopAutosave();
    this.router.navigate([entitySlugUrl('/marketplace/', item)], {
      relativeTo: this.route
    });
  }

  private validateConfirmEmail() {
    const emailControl = this.form.get(['alternateContact', 'email']) as UntypedFormControl;
    const confirmEmailControl = this.form.get(['alternateContact', 'confirmEmail']) as UntypedFormControl;

    combineLatest([
      emailControl.valueChanges.pipe(startWith(emailControl.value)),
      confirmEmailControl.valueChanges.pipe(startWith(confirmEmailControl.value))
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([email, confirmEmail]) => {
        const confirmEmailErrors = { ...confirmEmailControl.errors };
        if (email !== confirmEmail) {
          confirmEmailControl.setErrors({ ...confirmEmailErrors, matchEmail: true });
        } else {
          const shouldUpdateValidator = confirmEmailErrors.matchEmail;
          delete confirmEmailErrors.matchEmail;
          const errors = Object.values(confirmEmailErrors).length ? confirmEmailErrors : null;
          confirmEmailControl.setErrors(errors);
          shouldUpdateValidator && confirmEmailControl.patchValue(confirmEmail);
        }
      });
  }

  private scrollToFirstError() {
    setTimeout(() => {
      const error = this.document.querySelector('.mat-error');
      error?.scrollIntoView({ block: 'center', inline: 'nearest' });
    });
  }

  private toggleAmazonValidations(showAmazonLink: boolean) {
    const priceCtrl = this.form.get('price') as UntypedFormControl;
    const currencyCtrl = this.form.get('currency') as UntypedFormControl;
    const addressCtrl = this.form.get('address') as UntypedFormControl;
    const cityCtrl = addressCtrl.get('city') as UntypedFormControl;
    const stateCtrl = addressCtrl.get('state') as UntypedFormControl;
    const zipCtrl = addressCtrl.get('zip') as UntypedFormControl;
    const alternateContactCtrl = this.form.get('alternateContact') as UntypedFormControl;
    const emailCtrl = alternateContactCtrl.get('email') as UntypedFormControl;
    const confirmEmailCtrl = alternateContactCtrl.get('confirmEmail') as UntypedFormControl;

    const controlsToUpdate = [priceCtrl, currencyCtrl, cityCtrl, stateCtrl, zipCtrl, emailCtrl, confirmEmailCtrl];

    const amazonLinkCtrl = this.form.get('amazonAffiliateUrl') as UntypedFormControl;

    if (showAmazonLink) {
      addressCtrl.reset();
      alternateContactCtrl.reset();
      controlsToUpdate.forEach((ctrl) => ctrl.removeValidators(Validators.required));
      amazonLinkCtrl.addValidators(Validators.required);
    } else {
      controlsToUpdate.forEach((ctrl) => ctrl.addValidators(Validators.required));
      amazonLinkCtrl.removeValidators(Validators.required);
    }
    controlsToUpdate.forEach((ctrl) => ctrl.updateValueAndValidity());
    amazonLinkCtrl.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }
}
