<ct-card>
  <section class="post">
    <section class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="mb-0">{{ 'MARKETPLACE.ITEM_DETAILS.TITLE' | translate }}</h1>
      <ng-container *ngTemplateOutlet="isEditMode ? edit : create"></ng-container>
    </section>
    <form class="form" [formGroup]="form">
      <div class="row">
        <ct-toggle [formControl]="showAmazonLinkControl" class="col-12 mb-2">{{
          'I have Amazon Affiliate URL' | translate
        }}</ct-toggle>
        <ct-input
          *ngIf="showAmazonLinkInput"
          [label]="'Amazon Link' | translate"
          [placeholder]="'MARKETPLACE.ITEM_CREATE.LOCATION_LABEL_PLACEHOLDER' | translate"
          class="col-12"
          formControlName="amazonAffiliateUrl"
        ></ct-input>
      </div>
      <div *ngIf="!showAmazonLinkInput" formGroupName="address">
        <div class="row">
          <ct-input
            [label]="'MARKETPLACE.ITEM_CREATE.CITY' | translate"
            [placeholder]="'MARKETPLACE.ITEM_CREATE.CITY_PLACEHOLDER' | translate"
            class="col-12 col-md-4"
            formControlName="city"
          ></ct-input>
          <ct-input
            [label]="'MARKETPLACE.ITEM_CREATE.STATE' | translate"
            [placeholder]="'MARKETPLACE.ITEM_CREATE.STATE_PLACEHOLDER' | translate"
            class="col-12 col-md-4"
            formControlName="state"
          ></ct-input>
          <ct-input
            [label]="'MARKETPLACE.ITEM_CREATE.ZIP' | translate"
            [placeholder]="'MARKETPLACE.ITEM_CREATE.ZIP_PLACEHOLDER' | translate"
            class="col-12 col-md-4"
            formControlName="zip"
          ></ct-input>
          <ct-input
            [label]="'MARKETPLACE.ITEM_CREATE.LOCATION_LABEL' | translate"
            [placeholder]="'MARKETPLACE.ITEM_CREATE.LOCATION_LABEL_PLACEHOLDER' | translate"
            class="col-12"
            formControlName="locationLabel"
          ></ct-input>
        </div>
      </div>
      <div class="row">
        <ct-select
          class="col-12 col-md-4"
          formControlName="categoryId"
          [label]="'Category' | translate"
          [options]="categoriesList"
          [optional]="false"
        ></ct-select>
        <ct-select
          class="col-12 col-md-4"
          formControlName="subCategoryId"
          [label]="'Sub-Category' | translate"
          [options]="subCategoriesList"
          [optional]="false"
        ></ct-select>
      </div>
      <div class="row">
        <ct-input
          class="col-12"
          [label]="'MY_ACCOUNT.MY_POSTS_FEATURE.TITLE' | translate"
          [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.TITLE_PLACEHOLDER' | translate"
          formControlName="title"
        ></ct-input>
      </div>
      <div class="row">
        <ct-input
          class="col-12 col-md-6"
          [label]="'Brand / Make' | translate"
          [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.TITLE_PLACEHOLDER' | translate"
          formControlName="brand"
        ></ct-input>
        <ct-input
          class="col-12 col-md-6"
          [label]="'Model' | translate"
          [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.TITLE_PLACEHOLDER' | translate"
          formControlName="modelTitle"
        ></ct-input>
      </div>
      <div *ngIf="!showAmazonLinkInput" class="row">
        <ct-input
          class="col-12 col-md-4"
          type="number"
          [label]="'Price' | translate"
          [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.TITLE_PLACEHOLDER' | translate"
          formControlName="price"
        ></ct-input>
        <ct-select
          class="col-12 col-md-4"
          formControlName="currency"
          [label]="'Currency' | translate"
          [options]="currencyList"
          [optional]="false"
        ></ct-select>
        <ct-select
          class="col-12 col-md-4"
          formControlName="condition"
          [label]="'Condition' | translate"
          [options]="conditionsList"
          [optional]="false"
        ></ct-select>
      </div>
      <div class="row">
        <p class="col-12">
          <strong>{{ 'MARKETPLACE.ITEM_DETAILS.DESCRIPTION' | translate }}</strong>
        </p>
        <ct-text-editor class="col-12" formControlName="description" [modules]="modules"></ct-text-editor>
      </div>
      <div *ngIf="!showAmazonLinkInput" formGroupName="alternateContact">
        <div class="row">
          <h2 class="col-12">{{ 'MARKETPLACE.ITEM_CREATE.ALTERNATE_CONTACT_INFO' | translate }}</h2>
        </div>
        <div class="row">
          <ct-input
            [label]="'MARKETPLACE.ITEM_CREATE.FIRST_NAME' | translate"
            [placeholder]="'MARKETPLACE.ITEM_CREATE.FIRST_NAME_PLACEHOLDER' | translate"
            class="col-12 col-md-6"
            formControlName="firstName"
          ></ct-input>
          <ct-input
            [label]="'MARKETPLACE.ITEM_CREATE.LAST_NAME' | translate"
            [placeholder]="'MARKETPLACE.ITEM_CREATE.LAST_NAME_PLACEHOLDER' | translate"
            class="col-12 col-md-6"
            formControlName="lastName"
          ></ct-input>
        </div>
        <div class="row">
          <ct-input
            [label]="'MARKETPLACE.ITEM_CREATE.EMAIL_ADDRESS' | translate"
            [placeholder]="'MARKETPLACE.ITEM_CREATE.EMAIL_ADDRESS_PLACEHOLDER' | translate"
            class="col-12"
            formControlName="email"
          ></ct-input>
          <ct-input
            [label]="'MARKETPLACE.ITEM_CREATE.CONFIRM_EMAIL' | translate"
            [placeholder]="'MARKETPLACE.ITEM_CREATE.CONFIRM_EMAIL_PLACEHOLDER' | translate"
            class="col-12"
            formControlName="confirmEmail"
          ></ct-input>
          <ct-input
            [label]="'MARKETPLACE.ITEM_CREATE.PHONE_NUMBER' | translate"
            [placeholder]="'MARKETPLACE.ITEM_CREATE.PHONE_NUMBER_PLACEHOLDER' | translate"
            class="col-12 col-md-6"
            formControlName="phone"
          ></ct-input>
        </div>
      </div>
      <div class="mb-3">
        <h2 class="item-title">{{ 'MY_ACCOUNT.MY_POSTS_FEATURE.PHOTOS' | translate }}</h2>
        <ct-photo-grid
          [photos]="form.controls.photos?.value"
          [showPlaceholder]="true"
          [limit]="photosLimit"
          [removable]="true"
          (removed)="onRemovePhoto($event)"
          (placeholderSelected)="onAddPhotos()"
          [draggable]="true"
        ></ct-photo-grid>
      </div>
      <div class="d-flex">
        <ng-container *ngTemplateOutlet="isEditMode ? edit : create"></ng-container>
      </div>
    </form>
  </section>
</ct-card>

<ng-template #edit>
  <div class="d-flex">
    <ct-button [disabled]="isLoading" variant="flat" color="primary" (click)="onCancel()">{{
      'COMMON.CANCEL' | translate
    }}</ct-button>
    <ct-button [disabled]="isLoading" class="ml-2" variant="flat" color="accent" (click)="onUpdate()">{{
      'COMMON.UPDATE' | translate
    }}</ct-button>
    <ct-button [disabled]="isLoading" class="ml-2" variant="flat" color="warn" (click)="onToggleSold()">{{
      'MARKETPLACE.EDIT_ITEM.TOGGLE_SOLD' | translate
    }}</ct-button>
  </div>
</ng-template>

<ng-template #create>
  <ct-button [disabled]="isLoading" variant="flat" color="accent" (click)="onPublish()">{{
    'MARKETPLACE.EDIT_ITEM.CREATE' | translate
  }}</ct-button>
</ng-template>
